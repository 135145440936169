<template>
  <v-container fluid grid-list-md>
    <AppTitleBar>
      <template v-slot:title>{{ $t('labels.firms') }}</template>
      <template slot="actions">
        <v-tooltip top transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="create" v-on="on">
              <v-icon small v-text="$vuetify.icons.values.add" />
            </v-btn>
          </template>
          <span>{{ $t('newFirm') }}</span>
        </v-tooltip>
      </template>
    </AppTitleBar>
    <v-row no-gutters justify="end">
      <v-col cols="12" sm="6" md="4" lg="3">
        <SingleSearchFilter :context="context" :fields="searchFields" />
      </v-col>
    </v-row>

    <v-card flat class="main-card">
      <v-card-text class="py-1 px-0">
        <v-data-table
          :headers="headers"
          :items="firms"
          item-key="name"
          :loading="loading"
          :page.sync="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          must-sort
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
          :server-items-length="tableItemsCount"
          class="main-table elevation-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon :to="viewItem(item)">
              <v-icon>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.public`]="{ item }">
            <v-checkbox v-model="item.public" readonly />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { AppTitleBar, SingleSearchFilter } from '@argon/app/components'
import { NAMESPACE } from '../store'
import { Datatable } from '@argon/app/mixins'
import firmPerimeter from '../perimeters.js'

export default {
  components: {
    AppTitleBar,
    SingleSearchFilter
  },
  perimeters: [firmPerimeter],
  mixins: [Datatable],
  data() {
    return {
      newDialog: false,
      headers: [
        { text: this.$t('labels.displayName'), value: 'display' },
        { text: this.$t('labels.firmCode'), value: 'firm_code', width: 200 },
        { text: this.$t('labels.public'), value: 'public', width: 20 },
        { text: '', value: 'actions', sortable: false, align: 'right', width: 20 }
      ],
      searchFields: [{ label: 'display', type: 'text' }],
      loading: true,
      context: NAMESPACE
    }
  },
  computed: {
    ...mapState(NAMESPACE, ['firms', 'tableItemsCount'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['listFirms', 'clearFirms']),
    viewItem(item) {
      return {
        name: 'firmPage',
        params: { ...this.$route.params, firmId: item.id }
      }
    },
    create() {
      this.$router.push({ name: 'newFirm', params: { firmId: 'new' } })
    },
    fetchData() {
      return new Promise((resolve, reject) => {
        this.listFirms({ pager: this.pager })
          .then(resolve)
          .catch(reject)
          .finally(() => {
            this.loading = false
          })
      })
    }
  },

  destroyed() {
    this.clearFirms().catch(() => {})
  },
  created() {
    this.fetchData()
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData()
    next()
  }
}
</script>

<i18n>
{
  "en": {
    "newFirm": "New Firm"
  },
  "fr": {
    "newFirm": "Nouveau firm"
  }
}

</i18n>
