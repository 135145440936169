import { BasePerimeter } from '@argon/iam/perimeters'
import { ROLES } from '@argon/iam/utils'

export default new BasePerimeter({
  purpose: 'firm',
  can: {
    update(firm) {
      return this.hasAction(firm, 'update')
    },
    destroy(firm) {
      return this.hasAction(firm, 'delete')
    },
    list() {
      return this.hasPermission('firm:read')
    },
    create() {
      return this.hasPermission('firm:create')
    },
    atLeastAdvisor() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrAbove(org, ROLES.MEMBER)
    },
    atLeastManager() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrAbove(org, ROLES.MANAGER)
    }
  }
})
